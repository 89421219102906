<template>
  <div class="identify-step2">
    <CardHeader ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <div class="identify-profile-step-two">
        <p class="f-w6">この画像を提出しますか？</p>
        <div class="f-w3 text-left note">
          <p>事前にご登録頂いている個人情報と異なる場合は承認されません。</p>
        </div>
        <div
          class="
            edit-information
            d-flex
            justify-content-center
            align-items-center
            flex-column
          "
        >
          <div
            class="image_card d-flex justify-content-center align-items-center"
          >
            <img
              :src="image_url"
              :class="image_url ? '' : 'imageVisibility'"
              alt=""
            />
            <img
              v-if="!image_url"
              src="@/assets/image/credit_card/prego-download.svg"
              alt="prego-download"
              class="position-absolute prego-download"
            />
          </div>
          <div class="edit-information__input d-flex justify-content-between">
            <div class="d-flex flex-column align-items-center">
              <label v-if="isShowCamera">
                <b-form-file
                  :state="Boolean(file)"
                  class="d-none"
                  v-model="file"
                  accept="image/*"
                  capture="camera"
                  v-on:change="chooseImage($event)"
                  ref="form-file"
                />
                <img class="icon-camera" src="@/assets/image/Group2213.svg" />
              </label>
              <label v-else>
                <b-form-file
                  :state="Boolean(file)"
                  class="d-none"
                  v-model="file"
                  accept="image/*"
                  v-on:change="chooseImage($event)"
                  ref="form-file"
                />
                <img class="icon-camera" src="@/assets/image/Group2213.svg" />
              </label>
              <span class="f-w3 text-picture">身分証 の撮影</span>
            </div>
            <div class="d-flex flex-column align-items-center">
              <label>
                <b-form-file
                  :state="Boolean(file)"
                  class="d-none"
                  v-model="file"
                  accept="image/*"
                  v-on:change="chooseImage($event)"
                  ref="form-file"
                />
                <img class="icon-file" src="@/assets/image/Group2214.svg" />
              </label>
              <span class="f-w3 text-choose-file">ライブラリ から選択</span>
            </div>
          </div>
        </div>
        <div class="btn-submit f-w3">
          <button
            type="submit"
            class="btn-inactive"
            @click="submitImage()"
            :class="image_url == null ? 'btn-inactive' : 'btn-active'"
          >
            確定する
          </button>
        </div>
        <p class="f-w3 text__turorial" @click="nextEditProfile">
          個人情報を修正する
        </p>
      </div>
    </div>
    <CardFooter ref="footer" />
  </div>
</template>

<script>
import CardHeader from "@/components/CardHeader.vue";
import CardFooter from "@/components/CardFooter.vue";
import { mapGetters } from "vuex";
export default {
  name: "IdentifyProfileStepTwo",
  components: {
    CardHeader,
    CardFooter
  },
  data() {
    return {
      isShowImage: false,
      file: null,
      image_url: null,
      form: null,
      headerTitle: {
        text: "身分証明書の登録",
        isShowTitle: true,
        isShowLogo: false
      },
      isShowCamera: false,
      routeBackMessageDetail: false
    };
  },
  async created() {
    if (localStorage.getItem("back_user_" + this.auth.user_id)) {
      this.routeBackMessageDetail = true;
      localStorage.removeItem("back_user_" + this.auth.user_id);
    }
  },
  mounted() {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      window.innerWidth <= 699
    ) {
      this.isShowCamera = true;
    } else {
      this.isShowCamera = false;
    }
  },
  computed: {
    ...mapGetters({
      auth: "auth/user"
    })
  },
  methods: {
    async submitImage() {
      if (this.form === null) {
        this.$toast("画像を選択してください", "通知", "danger");
      } else {
        this.$root.$refs.loading.start();
        for (var value of this.form.values()) {
          let formData = new FormData();
          formData.append("image_url", value);
          formData.append("display_order", 1);
          formData.append("_method", "PUT");
          await this.$store
            .dispatch("registerIdentification/updateImage", formData)
            .then(() => {
              this.$root.$refs.loading.finish();
              this.$toast("画像のアップロードの成功", "お知らせ", "success");
              let routerName = "CastMyPageIdentifyProfileStepThree";
              if (this.auth.sex === 1) {
                routerName = "MenMyPageIdentifyProfileStepThree";
              }
              if (this.routeBackMessageDetail) {
                this.$router.go(-2);
              } else {
                this.$router.push({ name: routerName });
              }
            })
            .catch(error => {
              this.$root.$refs.loading.finish();
              throw error;
            });
        }
      }
    },
    chooseImage(event) {
      this.form = new FormData();
      this.form.append("image", event.target.files[0]);
      for (var value of this.form.values()) {
        if (
          value.type === "image/jpeg" ||
          value.type === "image/jpg" ||
          value.type === "image/png" ||
          value.type === "image/webp"
        ) {
          var reader = new FileReader();
          var vm = this;
          reader.onload = e => {
            vm.image_url = e.target.result;
          };
          reader.readAsDataURL(value);
        } else {
          this.$toast(
            "無効な画像形式. png、jpg、jpeg形式の画像を選択してください",
            "エラー",
            "danger"
          );
          return (this.form = null);
        }
      }
    },
    nextEditProfile() {
      if (this.auth.sex === 1) {
        this.$router.push({ name: "MenMyPagePersonalInformationEdit" });
      } else {
        this.$router.push({ name: "CastMyPagePersonalInformationEdit" });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_fontFamily.scss";
@import "~@/assets/scss/cast/identifyProfileStepTwo.scss";

.content-scroll {
  // overflow-y: scroll;
  padding-bottom: 40px;
  padding-top: 40px;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}

@media screen and (min-width: 1200px) {
  // .content-scroll {
  //   height: calc(100vh - 152px);
  // }
}
</style>
